require([
    'glider'
], function () {
    'use strict';

        const sliders = document.querySelectorAll("[id^=glider]");

        var customerData = getCustomerPreference(),
            organisationType = '';

        if (customerData && customerData.customer) {
            organisationType = customerData.customer.organisation_type;
        }

        sliders.forEach((glide) => {
            const gliderPrev = document.querySelector(`#${glide.id} .glider-prev`);
            const gliderNext = document.querySelector(`#${glide.id} .glider-next`);
            const slides = Array.from(document.querySelectorAll(`#${glide.id} .glider > .glider-slide`));
            var sorter = 'customerUnknown';

            switch (organisationType) {
                case 'P':
                    sorter = 'customerPrivate';
                    break;
                case 'C':
                    sorter = 'customerBusiness';
                    break;
                case 'G':
                    sorter = 'customerGovernmental';
                    break;
                case 'I':
                    sorter = 'customerInstaller';
                    break;
                default:
                    sorter = 'customerUnknown';
                    break;
            }

            slides.sort(function (a,b ) {
                return b.dataset[sorter] - a.dataset[sorter];
            });

            slides.forEach(function (el) {
                document.querySelectorAll(`#${glide.id} .glider`)[0].appendChild(el);
            });

            const glider = document.querySelector(`#${glide.id} .glider`);

            new Glider(glider, {
                type: 'carousel',
                slidesToShow: 6,
                itemWidth: 'auto',
                draggable: true,
                slidesToScroll: 1,
                rewind: true,
                dragVelocity: 1,
                scrollLock: true,
                dots: '.dots',
                arrows: {
                    prev: gliderPrev,
                    next: gliderNext
                },
                responsive: [
                    {
                        // screens greater than >= 320px
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 2.3
                        }
                    },{
                        // screens greater than >= 768px
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 'auto',
                            itemWidth: 200
                        }
                    },{
                        // screens greater than >= 1024px
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 5,
                            itemWidth: 'auto'
                        }
                    },{
                        // screens greater than >= 1440px
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 6,
                            itemWidth: 'auto'
                        }
                    }
                ]
            });

            var closest = glider.closest(".glider-contain");
            if (closest) {
                closest.style.visibility = 'visible';
            }
        });

        const categorySliders = document.querySelectorAll("[id^=category-slider]");
        categorySliders.forEach((glide) => {
            const gliderPrev = document.querySelector(`#${glide.id} .glider-prev`);
            const gliderNext = document.querySelector(`#${glide.id} .glider-next`);

            const glider = document.querySelector(`#${glide.id} .category-links__list`);

            var categoryGlider = new Glider(glider, {
                type: 'carousel',
                slidesToShow: 6,
                itemWidth: 'auto',
                draggable: true,
                slidesToScroll: 1,
                rewind: true,
                dragVelocity: 1,
                scrollLock: true,
                arrows: {
                    prev: gliderPrev,
                    next: gliderNext
                },
                responsive: [
                    {
                        // screens greater than >= 320px
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1.5,
                            itemWidth: 204
                        }
                    },{
                        // screens greater than >= 768px
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 'auto',
                            itemWidth: 204
                        }
                    },{
                        // screens greater than >= 1024px
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 5,
                            itemWidth: 204
                        }
                    },{
                        // screens greater than >= 1440px
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 6,
                            itemWidth: 204
                        }
                    }
                ]
            });

            const track = document.querySelector(`#${glide.id} .glider-track`);
            if (track) {
                if (track.clientWidth <= glider.clientWidth) {
                    gliderPrev.style.visibility = 'hidden';
                    gliderNext.style.visibility = 'hidden';

                    categoryGlider.setOption({
                        draggable: false
                    });
                }
            }
        });

        window.dispatchEvent(new Event('resize'));

    function getCustomerPreference()
    {
        const cacheStorage = localStorage.getItem('mage-cache-storage');
        if (!cacheStorage) {
            return null;
        }
        const parsedCache = JSON.parse(cacheStorage);
        if (!parsedCache || !parsedCache.hasOwnProperty('customer-preference')) {
            return null;
        }
        return parsedCache;
    }
});

